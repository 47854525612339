<template>
    <v-app>
        <v-app-bar
            app
            color="primary"
            dark
            absolute
            elevate-on-scroll
        >
            <v-app-bar-nav-icon 
                v-if="user._id"
                @click.stop="drawer = !drawer" />
        </v-app-bar>

        <v-main>
            <v-navigation-drawer
                v-model="drawer"
                absolute
                temporary
            >
                <v-list
                    nav
                    dense
                >
                    <v-list-item-group>
                        <v-list-item v-if="user.adminAccess && user.adminAccess.trips >= ACCESS.READ" @click="$router.push({ name: 'trips-list' })">
                            <v-list-item-title>{{ $t('src\\app.4559') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="user.adminAccess && user.adminAccess.bookings >= ACCESS.READ" @click="$router.push({ name: 'bookings-list' })">
                            <v-list-item-title>{{ $t('src\\app.5031') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="user.adminAccess && user.adminAccess.payments >= ACCESS.READ" @click="$router.push({ name: 'payments-list' })">
                            <v-list-item-title>{{ $t('src\\app.8651') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="user.adminAccess && user.adminAccess.payments >= ACCESS.WRITE" @click="$router.push({ name: 'bookings-need-refund' })">
                            <v-list-item-title>{{ $t('src\\app.1031') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="user.adminAccess && user.adminAccess.documents >= ACCESS.READ" @click="$router.push({ name: 'documents-list' })">
                            <v-list-item-title>{{ $t('src\\app.1735') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="user.adminAccess && user.adminAccess.reviews >= ACCESS.READ" @click="$router.push({ name: 'reviews-list' })">
                            <v-list-item-title>{{ $t('src\\app.4940') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="user.adminAccess && user.adminAccess.news >= ACCESS.READ" @click="$router.push({ name: 'news-list' })">
                            <v-list-item-title>{{ $t('src\\app.5731') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="user.adminAccess && user.adminAccess.reports >= ACCESS.READ" @click="$router.push({ name: 'reports-list' })">
                            <v-list-item-title>{{ $t('src\\app.reports') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="user.adminAccess && user.adminAccess.feedbacks >= ACCESS.READ" @click="$router.push({ name: 'feedback-list' })">
                            <v-list-item-title>{{ $t('src\\app.553') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="user.adminAccess && user.adminAccess.quizes >= ACCESS.READ" @click="$router.push({ name: 'quiz-list' })">
                            <v-list-item-title>{{ $t('src\\app.7721') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="user.adminAccess && user.adminAccess.waiting_lists >= ACCESS.READ" @click="$router.push({ name: 'waiting-list' })">
                            <v-list-item-title>{{ $t('src\\app.7722') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="user.adminAccess && user.adminAccess.users >= ACCESS.READ" @click="$router.push({ name: 'users-list' })">
                            <v-list-item-title>{{ $t('src\\app.8162') }}</v-list-item-title>
                        </v-list-item>
                        <v-divider />
                        <v-list-item v-if="user.adminAccess && user.adminAccess.trip_requests >= ACCESS.READ" @click="$router.push({ name: 'trip-requests-list' })">
                            <v-list-item-title>{{ $t('src\\app.3851') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="user.adminAccess && user.adminAccess.blogger_trips >= ACCESS.READ" @click="$router.push({ name: 'blogger-trips-list' })">
                            <v-list-item-title>{{ $t('src\\app.9808') }}</v-list-item-title>
                        </v-list-item>
                        <v-divider />
                        <v-list-item v-if="user.adminAccess && user.adminAccess.blogger_resources >= ACCESS.READ" @click="$router.push({ name: 'blogger-resources-list' })">
                            <v-list-item-title>{{ $t('src\\app.9809') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="user.adminAccess && user.adminAccess.dictionaries >= ACCESS.READ" @click="$router.push({ name: 'dictionaries-list' })">
                            <v-list-item-title>{{ $t('src\\app.5927') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="user.adminAccess && user.adminAccess.quiz_dictionaries >= ACCESS.READ" @click="$router.push({ name: 'quiz-dictionaries-list' })">
                            <v-list-item-title>{{ $t('src\\app.9364') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="user.adminAccess && user.adminAccess.page_templates >= ACCESS.READ" @click="$router.push({ name: 'page-templates-list' })">
                            <v-list-item-title>{{ $t('src\\app.3811') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="user.adminAccess && user.adminAccess.booking_settings >= ACCESS.READ" @click="$router.push({ name: 'booking-settings-list' })">
                            <v-list-item-title>{{ $t('src\\app.951') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="user.adminAccess && user.adminAccess.server_tasks >= ACCESS.READ" @click="$router.push({ name: 'server-tasks-list' })">
                            <v-list-item-title>{{ $t('src\\app.1703') }}</v-list-item-title>
                        </v-list-item>
                        <v-divider />
                        <v-list-item v-if="user.adminAccess && user.adminAccess.translates >= ACCESS.READ" @click="$router.push({ name: 'translates-list' })">
                            <v-list-item-title>{{ $t('src\\app.919') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="user.adminAccess && user.adminAccess.event_templates >= ACCESS.READ" @click="$router.push({ name: 'event-templates-list' })">
                            <v-list-item-title>{{ $t('src\\app.4107') }}</v-list-item-title>
                        </v-list-item>
                        <v-divider />
                        <v-list-item @click="logout">
                            <v-list-item-title>{{ $t('src\\app.3331') }}</v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
                <LaguageSwitcher />
            </v-navigation-drawer>
            <div class="ma-4">
                <router-view />
            </div>
        </v-main>
    </v-app>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ACCESS } from '@/vars';
    import LaguageSwitcher from './components/common/LaguageSwitcher.vue';

    export default {
        name: 'App',
        components: {
            LaguageSwitcher
        },
        data: () => ({
            ACCESS,
            drawer: null,
        }),
        computed: {
            ...mapState('auth', ['user']),
        },
        async mounted() {
            await store.dispatch('dictionaries/common');
            await store.dispatch('auth/fetch');
            if (!this.user._id) {
                await this.$router.push({ name: 'user-authorization' });
            }
        },
        methods: {
            async logout() {
                await store.dispatch('auth/logout');
                await this.$router.push({ name: 'user-authorization' });
            }
        }
    };
</script>

<style lang="scss">
</style>