import axios from '../../plugins/axios';

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        entity: {}
    },

    mutations: {
        SET_DICTIONARIES(state, payload) {
            for(const code in payload) {
                state.dictionaries[code] = payload[code];
            }
        },
        SET_ENTITIES(state, payload) {
            state.entities = payload.items;
            state.entitiesCount = payload.totalCount;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
    },

    actions: {
        async fetch({ commit }, { filter = {}, sorting = null, page = 1, itemsPerPage = 10 }) {
            const { data } = await axios.get('/admin/waiting-lists', { params: { filter, sorting, page, itemsPerPage } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async exportToCsv({ commit }, { filter = {} }) {
            const { data } = await axios.get('/admin/waiting-lists/export-to-csv', { params: { filter } });
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/admin/waiting-lists/${ id }`);
            commit('SET_ENTITY', data);
        },
        async delete({ commit }, { id }) {
            await axios.delete(`/admin/waiting-lists/${ id }`);
        },
    },

    getters: {}
}
