import axios from '../../plugins/axios';

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        entity: {}
    },

    mutations: {
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        CLEAR_ENTITY(state) {
            state.entity = {
                title: '',
                preview: '',
                text: '',
                picture: '',
            };
        },
    },

    actions: {
        async fetch({ commit }, { filter = {}, sorting = null }) {
            const { data } = await axios.get('/admin/blogger-trips', { params: { filter, sorting } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/admin/blogger-trips/${ id }`);
            commit('SET_ENTITY', data);
        },
        async sendEmail({ commit }, { id }) {
            const { data } = await axios.get(`/admin/blogger-trips/${ id }/send-published-email`);
            return data;
        },
        async sendSms({ commit }, { id }) {
            const { data } = await axios.get(`/admin/blogger-trips/${ id }/send-published-sms`);
            return data;
        },
        async save({ commit, state }) {
            if(state.entity._id) {
                return await axios.put(`/admin/blogger-trips/${ state.entity._id }`, state.entity);
            } else {
                return await axios.post('/admin/blogger-trips', state.entity);
            }
        },
        async setStatus({ commit, state }, { id, status }) {
            await axios.put(`/admin/blogger-trips/status/${ id }/${ status }`);
        },
        async delete({ commit }, { id }) {
            await axios.delete(`/admin/blogger-trips/${ id }`);
        },
    },

    getters: {}
}
