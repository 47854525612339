import Vue from 'vue';
import Vuex from 'vuex';

import dictionaries from './modules/dictionaries';
import pageTemplates from './modules/pageTemplates';
import quizDictionaries from './modules/quizDictionaries';
import translates from './modules/translates';
import auth from './modules/auth';
import files from './modules/files';
import users from './modules/users';
import trips from './modules/trips';
import bookings from './modules/bookings';
import documents from './modules/documents';
import bloggerResources from './modules/bloggerResources';
import payments from './modules/payments';
import reviews from './modules/reviews';
import news from './modules/news';
import reports from './modules/reports';
import feedbacks from './modules/feedbacks';
import quizes from './modules/quizes';
import waitingLists from './modules/waitingLists';
import serverTasks from './modules/serverTasks';
import bookingSettings from './modules/bookingSettings';
import tripRequests from './modules/tripRequests';
import bloggerTrips from './modules/bloggerTrips';
import eventTemplates from './modules/eventTemplates';
import settings from './modules/settings';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        dictionaries,
        pageTemplates,
        quizDictionaries,
        translates,
        auth,
        files,
        users,
        trips,
        bookings,
        documents,
        bloggerResources,
        payments,
        reviews,
        news,
        reports,
        feedbacks,
        quizes,
        waitingLists,
        serverTasks,
        bookingSettings,
        tripRequests,
        bloggerTrips,
        eventTemplates,
        settings,
    }
})
