import axios from '../../plugins/axios';

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        entity: {
            user: null,
            trip: null,
            documents: [{
                title: '',
                file: ''
            }],
            comment: null
        }
    },

    mutations: {
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        CLEAR_ENTITY(state) {
            state.entity = {
                user: null,
                trip: null,
                documents: [{
                    title: '',
                    file: ''
                }],
                comment: null
            };
        },
    },

    actions: {
        async fetch({ commit }, { filter = {}, sorting = null }) {
            const { data } = await axios.get('/admin/documents', { params: { filter, sorting } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/admin/documents/${ id }`);
            commit('SET_ENTITY', data);
        },
        async save({ commit, state }) {
            if(state.entity._id) {
                await axios.put(`/admin/documents/${ state.entity._id }`, state.entity);
            } else {
                await axios.post('/admin/documents', state.entity);
            }
        },
        async delete({ commit }, { id }) {
            await axios.delete(`/admin/documents/${ id }`);
        },
    },

    getters: {}
}
